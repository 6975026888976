.basic.tree {
  list-style: none;
  margin: 0;
  padding: 20px;
}
.basic .tree-node,
.basic .tree-node-group {
  list-style: none;
  margin: 0;
  padding: 0;
}

.basic .tree-branch-wrapper,
.basic .tree-node__leaf {
  outline: none;
}

/* .basic .tree-node--focused {
  outline-color: rgb(77, 144, 254);
  outline-style: auto;
  outline-width: 2px;
  display: block;
} */

/* .basic .tree-node__branch {
  display: block;
} */

.basic .tree-node {
  cursor: pointer;
}
